<template>
    <!-- 三方黑名单统计 -->
    <div id="black_three_stat_id">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_title">三方黑名单统计</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <!-- 查询条件 -->
            <el-row id="query_condition_id_202205200952" style="padding:0px 0;">
                <el-form :model="form_data" :inline="true" style="margin-bottom: 0px;" label-width="84px">
                    <el-form-item label="三方黑名单">
                        <el-select v-model="form_data.black_three_id" filterable size="mini" style="width:100%" placeholder="默认查询全部"> 
                            <el-option
                                v-for="item in blackThreeList"
                                :key="item.black_three_id"
                                :label="item.name + (item.black_three_id > 0 ?  '('+item.black_three_id+')' : '')"
                                :value="item.black_three_id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开始日期">
                        <el-date-picker
                            v-model="form_data.start_time"
                            type="date"
                            placeholder="选择日期" size="mini" style="width:125px;">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束日期">
                        <el-date-picker
                            v-model="form_data.end_time"
                            type="date"
                            placeholder="选择日期" size="mini" style="width:125px;">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submit_cha_xun()" size="mini">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
            <el-row style="overflow: auto;" :style="{height: ((content_heigth-query_condition_height)+'px')}">
                <el-table  :data="tableData" :show-header="true"  show-summary border style="width: 100%" size="mini">
                    <el-table-column prop="daytime" label="日期" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="black_three_name" label="三方黑名单" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="sum" label="请求成功次数" min-width="80" align="center"> </el-table-column>
                </el-table>
            </el-row>
        </el-row>
    </div>
</template>
<script>
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';

export default {

    components: {
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            form_data:{
                black_three_id: "",//查询条件-三方黑名单id 空字符表示全部
                start_time: new Date(),//开始日期
                end_time: new Date(),//结束日期
            },

            blackThreeList:[],//三方黑名单列表数据-请求接口获取

            tableData:[],//表格数据
            query_condition_height:40,//查询条件的高度
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();

        //获取三方黑名单列表数据-请求接口获取
        this.getBlackThreeList();
        //查询
        this.submit_cha_xun();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);
        },
        //获取三方黑名单列表数据-请求接口获取
        getBlackThreeList(){
            //请求接口
            API.UserServlet({
                param: "blackThreeList",
            }).then((res) => {
                if (res.recode === 0) {
                    this.blackThreeList = res.list;//三方黑名单列表数据-请求接口获取
                    var obj = {black_three_id: "", name: "默认查询全部" };
                    this.blackThreeList.unshift(obj);
                }
            });
        },
        //查询
        submit_cha_xun(){
            if(API.isEmtry(this.form_data.start_time)){
                this.$message.error("请选择开始日期");
                return;
            }
            if(API.isEmtry(this.form_data.end_time)){
                this.$message.error("请选择开始日期");
                return;
            }
            var start_time = dateFtt(this.form_data.start_time,"yyyy-MM-dd");
            var end_time = dateFtt(this.form_data.end_time,"yyyy-MM-dd");
            //请求接口
            API.OperationManagerServlet({
                param: "black_three_stat",
                black_three_id:this.form_data.black_three_id,
                start_time:start_time,
                end_time:end_time,
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;

                    this.$nextTick(function(){
                        //查询条件的高度
                        var query_condition = document.getElementById('query_condition_id_202205200952');
                        if(!API.isEmtry(query_condition)){
                            this.query_condition_height = query_condition.clientHeight;
                        }
                    });
                } 
            });
        },
        //返回
        goBack() {
            API.router_to("/operation_index");
        },
    }
};
</script>

<style scoped>
/*form表单样式调整*/
#app_check_rate_list>>>.el-form-item {
  margin-bottom: 0px;
}
</style>